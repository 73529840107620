import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import {
  Collapse,
  Divider,
  Flex,
  Img,
  Spinner,
  Text,
  Box,
  Grid,
  GridItem,
  Hide,
  Tooltip,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { WarningBekeu } from "../../design-system/overlay/WarningBekeu";

import { MyOrdersOrderId } from "../MyOrdersOrderId/MyOrdersOrderId";
import { MyOrdersBadge } from "../MyOrdersBadge/MyOrdersBadge";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { MyOrdersItemBadge } from "../MyOrdersItemBadge/MyOrdersItemBadge";
import { HistoryItem } from "../HistoryItem/HistoryItem";
import { MaxDeliveryTimeBadge } from "../MaxDeliveryTimeBadge/MaxDeliveryTimeBadge";
import MyOrdersDetail from "../MyOrdersDetail/MyOrdersDetail";

import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import notFoundImg from "../../design-system/images/not-found.png";
import warning from "../../design-system/icons/Shapes/warning-2.svg";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import * as auth from "../../pages/login/ducks/auth.duck";

import useFormatCurrency from "../../hooks/useFormatCurrency";
import TotalARSUSD from "../Common/TotalARSUSD";
import useFeatures from "../../hooks/useFeatures";

const DetailItem = ({
  orderItem,
  setClaim,
  claim,
  handleConfirmOrderCart,
  handleClaimOrderCart,
  handlePagination,
  noProduct,
  handleNoProduct,
  getHistory,
  item,
  itemArr,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  shippingIncluded,
  handleSetHistoryToCollapse,
  historyToCollapse,
  proUser,
  imputationUser
}) => {
  const cartsState = useSelector((state) => state?.carts);
  const [imageError, setImageError] = useState(false);
  const [newDeliveryArray, setNewDeliveryArray] = useState([]);
  const [maxDeliveryDay, setMaxDeliveryDay] = useState(0);
  const [isOpen, setIsOpen] = useState([]);
  const [totalServiceFee, setTotalServiceFee] = useState(0);

  const { ARS, USD } = useFormatCurrency();

  useEffect(() => {
    itemArr &&
      itemArr[0]?.buy_order_items?.map((orderItemVal) => {
        return {
          buy_order_item_id: orderItemVal?.id,
          qty_sent: orderItemVal?.quantity - orderItemVal?.quantity_sent,
          delivery_time: orderItemVal?.days_delivery_time_block,
        };
      });
  }, [itemArr]);

  useEffect(() => {
    if (orderItem && orderItem?.buy_order_items) {
      const deliveryArray = orderItem?.buy_order_items.reduce((acc, item) => {
        if (!acc[item?.days_delivery_time_block]) {
          acc[item?.days_delivery_time_block] = {
            days_delivery_time_block: [
              {
                ...item,
                days_delivery_time_block: item.days_delivery_time_block,
              },
            ],
          };
        } else {
          acc[item?.days_delivery_time_block]?.days_delivery_time_block.push({
            ...item,
            days_delivery_time_block: item.days_delivery_time_block,
          });
        }

        return acc;
      }, {});

      const maxDays = Math.max(
        ...Object.keys(deliveryArray).map((a) => Number(a)),
      );
      setMaxDeliveryDay(deliveryArray[maxDays]);

      const itemsCount = item?.buy_orders.reduce((acc, bo) => {
        acc += bo?.buy_order_items?.length;
        return acc;
      }, 0);

      const isOpenArray = new Array(itemsCount).fill(false);
      setIsOpen(isOpenArray);

      setNewDeliveryArray(Object.values(deliveryArray));

      setTotalServiceFee(orderItem?.service_fee);
    }
  }, [orderItem]);

  const handleGetHistory = (id) => {
    const newOpen = [...historyToCollapse];
    if (!newOpen[id]) {
      newOpen.fill(false);
      newOpen[id] = true;
    } else {
      newOpen.fill(false);
    }
    handleSetHistoryToCollapse(newOpen);

    !historyToCollapse[id] && getHistory({ order_id: orderItem.id });
  };

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"center"}
      alignItems="center"
      bg={"brand.bgGrey2"}
      px={["1rem", "1rem", "1rem", "0"]}
    >
      <BoxBekeu
        width={["100%", "100%", "100%", "95%"]}
        mt={"3rem"}
        boxShadow="none"
        border="0.0313rem solid"
        borderColor="brand.border"
        p={0}
      >
        <Flex flexDir={"column"}>
          <Flex
            alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            justifyContent={"space-between"}
            p="1.5rem"
            flexDir={["column", "column", "unset", "unset"]}
            rowGap={[3, 3, "unset", "unset"]}
          >
            <ProductDetailModalSeller
              sellerName={orderItem.provider?.fantasy_name}
              email={orderItem.provider?.email}
              phone={orderItem.provider?.phone}
            />
            <MyOrdersItemBadge item={orderItem}>
              {orderItem?.state === "1"
                ? "Pendiente de aprobación"
                : orderItem?.state === "2"
                  ? "Entrega en proceso"
                  : orderItem?.state === "2.1"
                    ? "Enviada por proveedor"
                    : orderItem?.state === "3"
                      ? "Cancelada"
                      : orderItem?.state === "3.1"
                        ? "Cancelada por aprobador"
                        : orderItem?.state === "3.2"
                          ? "Cancelada por proveedor"
                          : orderItem?.state === "3.3"
                            ? "Cancelada por ERP"
                            : orderItem?.state === "3.4"
                              ? "Cancelada por sistema"
                              : orderItem?.state === "4"
                                ? "Confirmada"
                                : orderItem?.state === "5"
                                  ? "Reclamada"
                                  : "Entrega en proceso"}
            </MyOrdersItemBadge>
          </Flex>

          <Divider />

          <Flex
            alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            justifyContent={"space-between"}
            px="1.5rem"
            flexDir={["column", "column", "unset", "unset"]}
            rowGap={[3, 3, "unset", "unset"]}
          >
            {orderItem?.currency_code && (
              <Text
                fontWeight={400}
                fontSize={"1rem"}
                color="brand.contentSecondary"
                py={["unset", "unset", "1.5rem", "1.5rem"]}
              >
                Importe en {orderItem?.currency_code}
              </Text>
            )}
            <Flex
              alignItems={["flex-start", "flex-start", "center", "center"]}
              justifyContent={["center", "center", "flex-end", "flex-end"]}
              flexDir={["column", "column", "row", "row"]}
              mt={["0", "0", "1rem", "0"]}>
              {!proUser && orderItem?.erp_number && (
              <Text
                fontWeight={400}
                fontSize={"1rem"}
                border="0.0625rem solid"
                borderColor={"brand.contentSecondary"}
                padding={"0.5rem"}
                borderRadius={"0.75rem"}
                color="brand.contentSecondary"
                mr={["0", "0", "1rem", "1rem"]}
                mb={["1rem", "1rem", "0", "0"]}
              >
                N° de Pedido SAP: {orderItem?.erp_number}
              </Text>
              )}
              {orderItem?.internal_code && (
              <Text
                fontWeight={400}
                fontSize={"1rem"}
                border="0.0625rem solid"
                borderColor={"brand.contentSecondary"}
                padding={"0.5rem"}
                borderRadius={"0.75rem"}
                color="brand.contentSecondary"
                mb={["1rem", "1rem", "0", "0"]}
              >
                N° de compra: {orderItem?.internal_code}
              </Text>
              )}
            </Flex>
          </Flex>

          {newDeliveryArray.map((deliveryArray, id) => (
            <Flex flexDir={"column"} key={id}>
              <Divider />
              <MaxDeliveryTimeBadge
                pro={proUser}
                days={
                  deliveryArray?.days_delivery_time_block[0]
                    ?.days_delivery_time_block
                }
                dates={moment(
                  deliveryArray?.days_delivery_time_block[0]
                    ?.date_delivery_time_block,
                  ['YYYY-MM-DD'], false
                ).format("DD/MM/YYYY")}
                m="1.5rem"
              />
              {deliveryArray?.days_delivery_time_block?.map((i, index) => (
                <Box key={`deliveryArray-${index}`}>
                  <Divider />
                  <Flex
                    justifyContent={"space-between"}
                    p="1.5rem"
                    gap="2.5rem"
                    overflowX={"auto"}
                  >
                    <Flex>
                      <Flex
                        w="5rem"
                        h="5rem"
                        mr="1.25rem"
                        border="0.0625rem solid"
                        borderColor={"brand.border"}
                        borderRadius={"0.75rem"}
                        justifyContent={"center"}
                        alignItems="center"
                        gap="1.5rem"
                      >
                        <Img
                          src={
                            imageError
                              ? notFoundImg
                              : i?.combination?.media?.image_source ||
                              i?.data_combination?.image_source
                          }
                          onError={() => setImageError(true)}
                          draggable={"false"}
                          objectFit="cover"
                          cursor={imageError ? "not-allowed" : "pointer"}
                          onClick={() => handleNoProduct(i)}
                          title={
                            imageError
                              ? "Imagen no disponible"
                              : i?.combination?.title ||
                              i?.data_combination?.title
                          }
                          h={imageError ? "50%" : "100%"}
                        />
                      </Flex>
                      <Flex flexDir={"column"} gap="0.75rem">
                        <Grid>
                          <H6
                            lineHeight={"1.5rem"}
                            _hover={{ color: "brand.hover" }}
                            transition="300ms ease all"
                            onClick={() => handleNoProduct(i)}
                            noOfLines={1}
                            cursor="pointer"
                            w={"20.3125rem"}
                            color={"brand.contentPrimary"}
                            fontWeight={600}
                          >
                            <Tooltip
                              bg={"white"}
                              color={"brand.contentPrimary"}
                              borderRadius={6}
                              p={2}
                              hasArrow
                              placement="top"
                              label={
                                i?.combination?.title ||
                                i?.data_combination?.title
                              }
                            >
                              {i?.combination?.title ||
                                i?.data_combination?.title}
                            </Tooltip>
                          </H6>
                          <H6>
                            {i?.currency_code}{" "}
                            {shippingIncluded ? (
                              <>
                                {i?.currency_code === "ARS"
                                  ? ARS.format(parseFloat(i?.price_unit))
                                  : USD.format(parseFloat(i?.price_unit))}
                              </>
                            ) : (
                              <>
                                {i?.currency_code === "USD"
                                  ? USD.format(
                                    parseFloat(i?.price_unit) +
                                    parseFloat(i?.unit_shipping_cost),
                                  )
                                  : ARS.format(
                                    parseFloat(i?.price_unit) +
                                    parseFloat(i?.unit_shipping_cost),
                                  )}{" "}
                              </>
                            )}{" "}
                            + Imp
                          </H6>
                        </Grid>

                        {proUser && (
                          <Flex gap="0.75rem">
                            <Paragraphs
                              lineHeight={"1.375rem"}
                              mb="0.1875rem"
                              fontWeight={600}
                            >
                              Tarifa de servicio:
                            </Paragraphs>

                            {i?.currency_code === "USD"
                              ? USD.format(parseFloat(i?.unit_service_fee))
                              : ARS.format(parseFloat(i?.unit_service_fee))}
                          </Flex>
                        )}

                        {imputationUser && (
                          <Flex flexDir={"column"}>
                            <Paragraphs
                              lineHeight={"1.375rem"}
                              fontWeight={600}
                            >
                              Centros de costo:
                            </Paragraphs>
                            {i?.cost_centers?.map((item, key) => (
                              <Flex
                                key={key}
                                justifyContent={"space-between"}
                                w="80%"
                              >
                                <Paragraphs lineHeight={"1.375rem"}>
                                  {item?.cost_center}
                                </Paragraphs>
                                <Paragraphs lineHeight={"1.375rem"}>
                                  {item?.percentage}%
                                </Paragraphs>
                              </Flex>
                            ))}
                          </Flex>
                        )}
                      </Flex>
                    </Flex>

                    <Flex
                      flexDir={"column"}
                      gap="0.75rem"
                      lineHeight={"1.375rem"}
                    >
                      <Text color={"brand.contentPrimary"} fontWeight={600}>
                        Cantidad comprada
                      </Text>
                      <Text color={"brand.contentPrimary"}>{i?.quantity}</Text>
                    </Flex>

                    <Flex
                      flexDir={"column"}
                      gap="0.75rem"
                      lineHeight={"1.375rem"}
                    >
                      <Text color={"brand.contentPrimary"} fontWeight={600}>
                        Cantidad ya enviada
                      </Text>
                      <Text color={"brand.contentPrimary"}>
                        {i?.quantity_sent}
                      </Text>
                    </Flex>

                    <Flex
                      flexDir={"column"}
                      gap="0.75rem"
                      lineHeight={"1.375rem"}
                    >
                      <Text color={"brand.contentPrimary"} fontWeight={600}>
                        Cantidad cancelada
                      </Text>
                      <Text color={"brand.contentPrimary"}>
                        {i?.quantity_cancel}
                      </Text>
                    </Flex>

                    <Flex
                      flexDir={"column"}
                      gap="0.75rem"
                      lineHeight={"1.375rem"}
                    >
                      <Text color={"brand.contentPrimary"} fontWeight={600}>
                        Cantidad confirmada
                      </Text>
                      <Text color={"brand.contentPrimary"}>
                        {i?.quantity_confirmed}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex px="1.5rem" pb="1.5rem">
                    <ButtonBekeuLink
                      w={"100%"}
                      justifyContent={"space-between"}
                      onClick={() => {
                        handleGetHistory(i?.collapseIndex);
                      }}
                      rightIcon={
                        <Img
                          style={{
                            transform: `rotate(${historyToCollapse[i?.collapseIndex] ? "180deg" : "0deg"})`,
                          }}
                          src={arrowDown}
                        />
                      }
                    >
                      Actividad del pedido
                    </ButtonBekeuLink>
                  </Flex>

                  <Collapse
                    in={historyToCollapse[i?.collapseIndex]}
                    animateOpacity
                    style={{
                      overflow: `${isOpen[i?.collapseIndex] ? "unset" : "hidden"}`,
                      zIndex: 999,
                    }}
                  >
                    {cartsState?.myOrdersHistoryLoading ? (
                      <Flex justifyContent={"center"} alignItems="center">
                        <Spinner marginBottom="2.75rem" />
                      </Flex>
                    ) : (
                      <div
                        style={{
                          marginBottom: "2.75rem",
                          padding: "1.5rem",
                        }}
                      >
                        {cartsState?.myOrdersHistory &&
                          cartsState?.myOrdersHistory?.length > 0 ? (
                          cartsState?.myOrdersHistory?.filter(
                            (filterItem) =>
                              filterItem?.dispatch_note?.buy_order_item_id ===
                              i.id,
                          ).length > 0 ? (
                            cartsState?.myOrdersHistory
                              ?.filter(
                                (filterItem) =>
                                  filterItem?.dispatch_note
                                    ?.buy_order_item_id === i.id,
                              )
                              .map((note, id) => (
                                <HistoryItem
                                  msg={note}
                                  key={id}
                                  handleConfirmOrderCart={
                                    handleConfirmOrderCart
                                  }
                                  setFileNameInput={setFileNameInput}
                                  fileNameInput={fileNameInput}
                                  handlePagination={handlePagination}
                                  orderItem={orderItem}
                                  setClaim={setClaim}
                                  handleClaimOrderCart={handleClaimOrderCart}
                                  providerName={
                                    orderItem.provider?.fantasy_name
                                  }
                                  claim={claim}
                                  myOrders
                                  product={i}
                                  deliveryArray={deliveryArray}
                                  newDeliveryArray={newDeliveryArray}
                                  sapAvailable={sapAvailable}
                                  dispatchNoteAvailable={dispatchNoteAvailable}
                                  shippingIncluded={shippingIncluded}
                                  fullHistory={cartsState?.myOrdersHistory?.filter(
                                    (filterItem) =>
                                      filterItem?.dispatch_note
                                        ?.buy_order_item_id === i.id,
                                  )}
                                />
                              ))
                          ) : (
                            <WarningBekeu
                              fontSize={"1rem"}
                              icon={warning}
                              whidth={"100%"}
                              boxShadow="lg"
                            >
                              Este pedido aún no tiene movimientos.
                            </WarningBekeu>
                          )
                        ) : (
                          <WarningBekeu
                            fontSize={"1rem"}
                            icon={warning}
                            whidth={"100%"}
                            boxShadow="lg"
                          >
                            Este pedido aún no tiene movimientos.
                          </WarningBekeu>
                        )}
                      </div>
                    )}
                  </Collapse>
                </Box>
              ))}
            </Flex>
          ))}

          {noProduct && (
            <Text
              fontSize={"sm"}
              mb={4}
              className="animate__animated animate__fadeIn"
            >
              Este producto no se encuentra disponible para comprar nuevamente
            </Text>
          )}

          <Divider />

          <Flex
            flexDir={"column"}
            alignItems="flex-end"
            mt={"16px"}
            gap="12px"
            p="24px"
          >
            {orderItem?.total_ARP && parseInt(orderItem?.total_ARP) !== 0 ? (
              <>
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Subtotal productos en ARS:</H6>{" "}
                  <H6 fontWeight={400}>
                    {shippingIncluded
                      ? ARS.format(parseFloat(orderItem?.total_w_shipping_ARP))
                      : ARS.format(parseFloat(orderItem?.subtotal_ARS))}{" "}
                    + Imp
                  </H6>
                </Flex>

                {proUser &&
                  (
                    <Flex justifyContent={"space-between"} gap={"1rem"}>
                      <H6 fontWeight={400}>Total tarifa de servicio en ARS:</H6>{" "}
                      <H6 fontWeight={400}>
                        {ARS.format(parseFloat(totalServiceFee))} + Imp
                      </H6>
                    </Flex>
                  )
                }

                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Costo de envío en ARS:</H6>
                  {shippingIncluded ||
                    !orderItem?.shipping_cost_ARP ||
                    parseInt(orderItem?.shipping_cost_ARP) === 0 ? (
                    <H6 fontWeight={400} color="brand.success">
                      Incluído
                    </H6>
                  ) : (
                    <H6 fontWeight={400}>
                      {ARS.format(parseFloat(orderItem?.shipping_cost_ARP))} +
                      Imp
                    </H6>
                  )}
                </Flex>

                {shippingIncluded ||
                  !orderItem?.shipping_cost_ARP ||
                  parseInt(orderItem?.shipping_cost_ARP) === 0 ? (
                  <H5>
                    Total en ARS{" "}
                    {proUser ? (
                      <>
                        {ARS.format(
                          parseFloat(orderItem?.total_w_shipping_ARP) +
                          parseFloat(orderItem?.service_fee),
                        )}{" "}
                        + Imp
                      </>
                    ) : (
                      <>
                        {ARS.format(
                          parseFloat(orderItem?.total_w_shipping_ARP)
                        )}{" "}
                        + Imp
                      </>
                    )}
                  </H5>
                ) : (
                  <H5>
                    Total en ARS{" "}
                    {proUser ? (
                      <>
                        {ARS.format(
                          parseFloat(orderItem?.total_ARP) +
                          parseInt(orderItem?.shipping_cost_ARP) +
                          parseFloat(orderItem?.service_fee),
                        )}{" "}
                        + Imp
                      </>
                    ) : <>
                      {ARS.format(
                        parseFloat(orderItem?.total_ARP) +
                        parseInt(orderItem?.shipping_cost_ARP)
                      )}{" "}
                      + Imp
                    </>
                    }
                  </H5>
                )}
              </>
            ) : (
              <></>
            )}

            {orderItem?.total_USD && parseInt(orderItem?.total_USD) !== 0 ? (
              <>
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Subtotal productos en USD:</H6>{" "}
                  <H6 fontWeight={400}>
                    {shippingIncluded
                      ? USD.format(parseFloat(orderItem?.total_w_shipping_USD))
                      : USD.format(parseFloat(orderItem?.total_USD))}{" "}
                    + Imp
                  </H6>
                </Flex>
                {proUser && (
                  <Flex justifyContent={"space-between"} gap={"1rem"}>
                    <H6 fontWeight={400}>Total tarifa de servicio en USD:</H6>{" "}
                    <H6 fontWeight={400}>
                      {USD.format(parseFloat(totalServiceFee))} + Imp
                    </H6>
                  </Flex>
                )}
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Costo de envío en USD:</H6>
                  {shippingIncluded ||
                    !orderItem?.shipping_cost_USD ||
                    parseInt(orderItem?.shipping_cost_USD) === 0 ? (
                    <H6 fontWeight={400} color="brand.success">
                      Incluído
                    </H6>
                  ) : (
                    <H6 fontWeight={400}>
                      {USD.format(parseFloat(orderItem?.shipping_cost_USD))} +
                      Imp
                    </H6>
                  )}
                </Flex>
                {shippingIncluded ||
                  !orderItem?.shipping_cost_USD ||
                  parseInt(orderItem?.shipping_cost_USD) === 0 ? (
                  <H5>
                    Total en USD{" "}
                    {proUser ? (
                      <>
                        {USD.format(
                          parseFloat(orderItem?.total_w_shipping_USD) +
                          parseFloat(orderItem?.service_fee)
                        )}{" "}
                        + Imp
                      </>
                    ) : (
                      <>
                        {USD.format(
                          parseFloat(orderItem?.total_w_shipping_USD),
                        )}{" "}
                        + Imp
                      </>
                    )}
                  </H5>
                ) : (
                  <H5>
                    Total en USD{" "}
                    {proUser ? (
                      <>
                        {USD.format(
                          parseFloat(orderItem?.total_USD) +
                          parseInt(orderItem?.shipping_cost_USD) +
                          parseFloat(orderItem?.service_fee)
                        )}{" "}
                        + Imp
                      </>
                    ) : (
                      <>
                        {USD.format(
                          parseFloat(orderItem?.total_USD) +
                          parseInt(orderItem?.shipping_cost_USD)
                        )}{" "}
                        + Imp
                      </>
                    )}
                  </H5>
                )}
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      </BoxBekeu>
    </Flex>
  );
};

const MyOrdersItem = ({
  item,
  type,
  showitems,
  selected,
  status,
  getOneProduct,
  handleConfirmOrderCart,
  handleClaimOrderCart,
  handlePagination,
  user,
  claim,
  setClaim,
  getHistory,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  shippingIncluded,
  handleSetHistoryToCollapse,
  historyToCollapse,
  handlePushErrors,
  ...props
}) => {
  const history = useHistory();
  const [noProduct, setNoProduct] = useState(false);
  const [maxDeliveryDay, setMaxDeliveryDay] = useState({});
  const [newItem, setNewItem] = useState({});
  const [approvedDate, setApprovedDate] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");

  const { proUser, imputationUser } = useFeatures();
  const authState = useSelector((state) => state?.auth);

  useEffect(() => {
    if (item?.buy_orders && Object.keys(item).length > 0) {
      let deliveryArray = {};
      let _newItem = {
        ...item,
        buyOrders: item?.buy_orders.map((bo) => ({
          ...bo,
          items: bo?.buy_order_items.map((boi) => {
            if (!deliveryArray[boi?.days_delivery_time_block]) {
              deliveryArray[boi?.days_delivery_time_block] = {
                days_delivery_time_block: [
                  {
                    days_delivery_time_block: boi.days_delivery_time_block,
                    date_delivery_time_block: boi.date_delivery_time_block,
                  },
                ],
              };
            } else {
              deliveryArray[
                boi?.days_delivery_time_block
              ]?.days_delivery_time_block.push({
                days_delivery_time_block: boi.days_delivery_time_block,
                date_delivery_time_block: boi.date_delivery_time_block,
              });
            }

            return {
              ...boi,
              subtotal_shipping_cost_ARP: boi?.subtotal_shipping_ARS,
              subtotal_shipping_cost_USD: boi?.subtotal_shipping_USD,
            };
          }),
        })),
      };

      if (Object.values(deliveryArray).length > 0) {
        const maxDays = Math.max(
          ...Object.keys(deliveryArray).map((a) => Number(a)),
        );

        setMaxDeliveryDay(
          deliveryArray[maxDays]?.days_delivery_time_block[0]
            ?.date_delivery_time_block,
        );
      }

      if (item && item?.buy_orders) {
        !item?.buy_orders[0]?.sent_for_approval_at
          ? setPurchaseDate(item?.buy_orders[0]?.exported_at)
          : setPurchaseDate(item?.buy_orders[0]?.sent_for_approval_at);

        setApprovedDate(
          moment(item?.buy_orders[0]?.exported_at, ['YYYY-MM-DD'], false).format("DD/MM/YYYY"),
        );
      } else {
        setApprovedDate("");
      }

      setNewItem(_newItem);
    }
  }, [item]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  function handleNoProduct(i) {
    handleScrollTo();
    if (i.combination) {
      history.push(`/detail/${i?.sku}`);
    } else {
      setNoProduct(!noProduct);
    }
  }

  return (
    <BoxBekeu p={0} overflow={"unset"} pb={"3rem"} {...props}>
      <Flex flexDir={"column"}>
        <Flex
          flexDir={["column", "column", "column", "unset"]}
          alignItems={["flex-start", "flex-start", "flex-start", "center"]}
          justifyContent={"space-between"}
          p="1.5rem"
          rowGap={[3, 3, 3, "unset"]}
        >
          <Grid>
            <Flex flexDir={["column", "column", "row", "row"]}>
              <Flex gap={2} flexDir={["column", "row"]}>
                <H6 fontWeight={600}>Fecha de compra:</H6>
                <Text fontSize={"1.125rem"}>
                  {moment(purchaseDate, ['YYYY-MM-DD'], false).format("DD/MM/YYYY")}
                </Text>
              </Flex>

              {!showitems &&
                approvedDate !== "Fecha inválida" &&
                approvedDate !== "" && authState?.features && authState?.features?.includes("company_approvals") && (
                  <>
                    <Flex gap={2} flexDir={["column", "row"]}>
                      <Hide below="md">
                        <Text ml={2}> | </Text>
                      </Hide>
                      <H6 fontWeight={600} color={"brand.green"}>
                        Fecha de aprobación:
                      </H6>
                      <Text fontSize={"1.125rem"}>{approvedDate}</Text>
                    </Flex>
                  </>
                )}
            </Flex>

            {!showitems && (
              <Flex gap={2} flexDir={["column", "row"]}>
                <H6 fontWeight={600}>Fecha máxima de entrega:</H6>
                <Text fontSize={"1.125rem"}>
                  {moment(maxDeliveryDay, ['YYYY-MM-DD'], false).format("DD/MM/YYYY")}
                </Text>
              </Flex>
            )}
          </Grid>

          <Flex
            flexDir={["column", "column", "unset", "unset"]}
            rowGap={[3, 3, 3, "unset"]}
          >
            <MyOrdersOrderId mr={4}>
              Nº de carrito: {item?.internal_code}
            </MyOrdersOrderId>
            <MyOrdersBadge item={item}>
              {item?.state === "1"
                ? "Pendiente de aprobación"
                : item?.state === "2"
                  ? "Entrega en proceso"
                  : item?.state === "3"
                    ? "Cancelada"
                    : item?.state === "4"
                      ? "Finalizada"
                      : "Entrega en proceso"}
            </MyOrdersBadge>
          </Flex>
        </Flex>

        <Divider />

        {showitems && (
          <>
            <Grid
              templateColumns={["unset", "unset", "unset", "repeat(2,1fr)"]}
              p={"1.5rem"}
            >
              <GridItem>
                <Box>
                  <Paragraphs fontWeight={600} color={"black"}>
                    Dirección de entrega
                  </Paragraphs>
                </Box>

                <Box>
                  <Text lineHeight="1.2rem" color={"brand.contentSecondary"}>
                    {item?.address?.name}
                  </Text>
                </Box>
              </GridItem>

              {item?.buy_orders && (item?.buy_orders[0]?.client_id !== item?.buy_orders[0]?.approver_id) && (
                <GridItem>
                  <Box>
                    <Paragraphs fontWeight={600} color={"black"}>
                      Aprobador
                    </Paragraphs>
                  </Box>
                  <Box>
                    <Text lineHeight="1.2rem" color={"brand.contentSecondary"}>
                      {item?.buy_orders[0]?.approver?.user
                        ? item?.buy_orders[0]?.approver?.user?.email
                        : item?.buy_orders[0]?.temp_approver
                          ? item?.buy_orders[0]?.temp_approver?.user?.email
                          : "-"}
                    </Text>
                  </Box>
                </GridItem>
              )}
            </Grid>
          </>
        )}

        {newItem && (
          <MyOrdersDetail
            item={newItem}
            showitems={showitems}
            shippingIncluded={shippingIncluded}
            handlePushErrors={handlePushErrors}
          />
        )}

        <Divider />

        {showitems && newItem && (
          <TotalARSUSD item={newItem} shippingIncluded={shippingIncluded} proUser={proUser} />
        )}

        {showitems &&
          item?.buy_orders &&
          item?.buy_orders.map((orderItem, id, itemArr) => (
            <DetailItem
              handleConfirmOrderCart={handleConfirmOrderCart}
              handleClaimOrderCart={handleClaimOrderCart}
              item={item}
              maxDeliveryDay={maxDeliveryDay}
              key={id}
              orderItem={orderItem}
              getOneProduct={getOneProduct}
              history={history}
              handlePagination={handlePagination}
              setClaim={setClaim}
              claim={claim}
              noProduct={noProduct}
              handleNoProduct={handleNoProduct}
              getHistory={getHistory}
              itemArr={itemArr}
              setFileNameInput={setFileNameInput}
              fileNameInput={fileNameInput}
              sapAvailable={sapAvailable}
              dispatchNoteAvailable={dispatchNoteAvailable}
              shippingIncluded={shippingIncluded}
              historyToCollapse={historyToCollapse}
              handleSetHistoryToCollapse={handleSetHistoryToCollapse}
              proUser={proUser}
              imputationUser={imputationUser}
            />
          ))}
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, { ...product.actions, ...auth.actions })(MyOrdersItem),
);